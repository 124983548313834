import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { bookingsWidgetPageLoaded } from '@wix/bi-logger-wixboost-ugc/v2';
import { BookingsApiDeprecated } from '../../api/BookingsApiDeprecated';
import { REQUESTED_STAFF_DEEP_LINK_ORIGIN } from '../../consts';
import { SettingsKeys, WidgetData } from '../../../legacy/types';
import {
  getAppSettingsClient,
  getUserSettings,
  updatePublicData,
} from '../../../legacy/appSettings/getAppSettings';
import {
  mergeAppSettingsToSettingsParams,
  mergeAppSettingsToStyleParams,
  ServiceListSettings,
} from '../../../legacy/appSettings/appSettings';
import { WidgetNamePhase1 } from '../../utils/bi/consts';
import {
  createWidgetErrorStateViewModel,
  createWidgetViewModel,
  WidgetViewModel,
} from '../../viewModel/viewModel';
import { createWidgetActions } from '../../actions/actions';
import {
  EnrichedService,
  FilterOption,
  ServicesPagingMetadata,
  ViewMode,
} from '../../types/types';
import { shouldShowDummyContent } from '../../utils/dummyContent/dummyContent';
import { getPresetId, onLocationURLChange } from './controllerPrePageReady';
import { mergeOpacityToColor } from '../../../legacy/utils';
import { mapPublicDataOverridesToPublicData } from '../../utils/anywhere/anywhere';
import {
  navigateToHeadlessIfNeeded,
  NavigationType,
} from '@wix/wix-to-headless-redirect-client';
import {
  mergePresetIdToPublicData,
  mergePresetIdToStyleParams,
} from './updateSettings';
import { filterWidgetData } from '../../utils/filters/filter-widget-data';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import {
  getScale,
  getUrlQueryParamValue,
  isRunningInIframe,
  BookingsQueryParams,
  isPricingPlanInstalled as isPricingPlanInstalledUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { BookingsAPI } from '../../api/BookingsApi';
import {
  enrichServices,
  getBusinessLocationsFromServices,
  mapServiceToServiceV2,
} from '../../utils/services/services';
import {
  ServiceListContext,
  createServiceListContext,
} from '../../context/createServiceListContext';
import {
  Location,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';
import {
  createDummyFilterOptions,
  createDummyServicesDto,
} from '../../utils/dummyContent/dummyServicesDto/dummyServicesDto';
import {
  GetActiveFeaturesResponse,
  GetBusinessResponse,
} from '@wix/ambassador-services-catalog-server/types';
import { ActiveFeatures } from '@wix/bookings-uou-types';
import { getPlatformBiLoggerDefaultsConfig } from '../../utils/bi/getPlatformBiLoggerDefaultsConfig';
import {
  getFilterOptions,
  isListFilterVisible,
  isShowAllServicesFilterOptionVisible,
} from '../../utils/filterOptions/getFilterOptions';
import settingsParams from './settingsParams';
import {
  calculateNumberOfPages,
  getPaginationSEOMetadata,
  getRequestedPageFromQueryParam,
} from '../../utils/pagination/pagination';

export const createControllerFactory = (viewMode: ViewMode) => {
  const createController: CreateControllerFn = async ({
    flowAPI,
    dangerousPublicDataOverride,
    dangerousStylesOverride,
  }: ControllerParams) => {
    const {
      controllerConfig,
      environment: { isSSR, isEditor, isEditorX, isADI },
      bi,
      experiments,
    } = flowAPI;
    const { config, wixCodeApi, setProps } = controllerConfig;

    let businessLocations: Location[];
    let bookingsApi: BookingsAPI;
    let enrichedServices: EnrichedService[];
    let serviceListContext: ServiceListContext;
    let widgetViewModel: WidgetViewModel;
    let servicesPagingMetadata: ServicesPagingMetadata;
    let scale: number;
    let currentUserAppSettings: ServiceListSettings,
      currentUserStylesParam: any;
    let publicData: any,
      stylesProp: any,
      shouldWorkWithAppSettings: boolean,
      userSettings: any;

    let presetId = getPresetId(config, isEditorX);

    const networkCache = new Map<string, any>();

    const biLoggerDefaultConfigurations = getPlatformBiLoggerDefaultsConfig(
      flowAPI,
      WidgetNamePhase1,
    );
    bi?.updateDefaults(biLoggerDefaultConfigurations);

    onLocationURLChange(wixCodeApi, () => pageReady());

    // When moving to app reflow, this should move to the App Reflow router (using getHeadlessUrl instead of navigateToHeadlessIfNeeded)
    const { navigatedToHeadless } = navigateToHeadlessIfNeeded({
      navParams: {
        logicalName: NavigationType.BOOKINGS_SERVICE_LIST,
      },
      location: wixCodeApi.location,
    });
    if (navigatedToHeadless) {
      // stop rendering
      return {
        pageReady: async () => {},
      };
    }

    const pageReady = async () => {
      const isMigrateServiceListToServiceV2Enabled = experiments.enabled(
        'specs.bookings.migrateServiceListToServiceV2',
      );

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { config } = controllerConfig;
      const appSettingsClient = getAppSettingsClient(flowAPI);

      shouldWorkWithAppSettings = !!config.externalId;


      let filterOptions: FilterOption[] = [];

      const setErrorState = (error: unknown) => {
        console.error(error);
        const widgetErrorStateViewModel = createWidgetErrorStateViewModel({
          flowAPI,
        });
        setProps({
          widgetErrorStateViewModel,
        });
      };

      if (shouldWorkWithAppSettings) {
        try {
          userSettings = await getUserSettings(appSettingsClient, presetId);
        } catch (error) {
          return setErrorState(error);
        }
      }

      const isPricingPlanInstalled = await isPricingPlanInstalledUtils(
        flowAPI.controllerConfig.wixCodeApi,
      );

      let services: Service[];
      let activeFeatures: GetActiveFeaturesResponse;

      bookingsApi = new BookingsAPI({
        appSettings: userSettings,
        flowAPI,
        shouldWorkWithAppSettings,
      });

      if (!isMigrateServiceListToServiceV2Enabled) {
        const bookingsApiDeprecated = new BookingsApiDeprecated({
          flowAPI,
          shouldWorkWithAppSettings,
          networkCache,
        });
        try {
          let widgetData: WidgetData;
          [filterOptions, widgetData] = await Promise.all([
            experiments.enabled('specs.bookings.fetchTabsInServiceList')
              ? getFilterOptions({
                  flowAPI,
                  bookingsApi,
                  appSettings: shouldWorkWithAppSettings && userSettings,
                })
              : [],
            bookingsApiDeprecated.getWidgetData(),
          ]);
          const {
            config: { activeFeatures: deprecatedActiveFeatures, businessInfo },
          } = widgetData;

          const { offerings } = filterWidgetData(
            widgetData,
            flowAPI,
            presetId,
            isEditor,
          );

          services = offerings.map((service) =>
            mapServiceToServiceV2({ service }),
          );
          activeFeatures =
            mapDeprecatedActiveFeaturesToGetActiveFeaturesResponse(
              JSON.parse(deprecatedActiveFeatures),
            );
          serviceListContext = await createServiceListContext({
            businessInfo,
            isMultiServiceAppointmentEnabled:
              businessInfo.isMultiServicesAppointmentsEnable,
            flowAPI,
            isPricingPlanInstalled,
          });
          servicesPagingMetadata = {
            totalPages: 1,
            minPageLoaded: 1,
            maxPageLoaded: 1,
          };
        } catch (error) {
          return setErrorState(error);
        }
      } else {
        let queryServicesResponse, getBusinessResponse;
        const getBusinessInfoPromise = bookingsApi
          .getBusinessInfo()
          .catch((error) => {
            console.log(error);
            const defaultBusinessInfo: GetBusinessResponse = {
              activeFeatures: {
                applicableForCourse: true,
                applicableForGroups: true,
                applicableForIndividual: true,
                applicableForPayments: true,
                applicableForReminders: true,
                applicableForSmsReminders: true,
              },
              businessProperties: {},
              info: {
                name: '',
                language: 'en',
                timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
                locale: 'en',
              },
            };

            return defaultBusinessInfo;
          });

        if (
          experiments.enabled('specs.bookings.fetchTabsInServiceList') &&
          isListFilterVisible({
            flowAPI,
            appSettings: shouldWorkWithAppSettings && userSettings,
          }) &&
          !isShowAllServicesFilterOptionVisible({
            appSettings: shouldWorkWithAppSettings && userSettings,
            flowAPI,
          })
        ) {
          filterOptions = await getFilterOptions({
            flowAPI,
            bookingsApi,
            appSettings: shouldWorkWithAppSettings && userSettings,
          });

          const selectedOption = filterOptions.find(
            (option) => option.isSelected,
          );

          [queryServicesResponse, getBusinessResponse] = await Promise.all([
            bookingsApi
              .queryServices({
                selectedFilterOptionId: selectedOption?.id,
              })
              .catch((error) => {
                return setErrorState(error);
              }),
            getBusinessInfoPromise,
          ]);
        } else {
          [filterOptions, queryServicesResponse, getBusinessResponse] =
            await Promise.all([
              experiments.enabled('specs.bookings.fetchTabsInServiceList')
                ? getFilterOptions({
                    flowAPI,
                    bookingsApi,
                    appSettings: shouldWorkWithAppSettings && userSettings,
                  })
                : [],
              bookingsApi.queryServices().catch((error) => {
                return setErrorState(error);
              }),
              getBusinessInfoPromise,
            ]);
        }

        if (!queryServicesResponse) {
          return;
        }

        const { pagingMetadata, services: initialServices } =
          queryServicesResponse;
        const { total, offset } = pagingMetadata || {};
        const servicesPerPage = flowAPI.settings.get(
          settingsParams.servicesPerPage,
        ) as number;
        const requestedPage = offset ? offset / servicesPerPage + 1 : 1;

        services = initialServices ?? [];

        servicesPagingMetadata = {
          totalPages: calculateNumberOfPages(servicesPerPage, total),
          minPageLoaded: requestedPage,
          maxPageLoaded: requestedPage,
        };

        activeFeatures = getBusinessResponse.activeFeatures!;

        serviceListContext = await createServiceListContext({
          getBusinessResponse,
          flowAPI,
          isPricingPlanInstalled,
        });
      }

      const { isAnywhereFlow: isAnywhereFlowInd, businessInfo } =
        serviceListContext;

      enrichedServices = await enrichServices({
        flowAPI,
        activeFeatures,
        isPricingPlanInstalled,
        services,
        isAnywhereFlow: serviceListContext.isAnywhereFlow,
      });
      businessLocations = getBusinessLocationsFromServices(enrichedServices);

      scale = await getScale();

      if (shouldWorkWithAppSettings) {
        const userStylesColorsWithOpacity = {};
        (
          Object.keys(config.style.styleParams.colors || {}) as SettingsKeys[]
        ).forEach((colorKey) => {
          // @ts-expect-error
          userStylesColorsWithOpacity[colorKey] = {
            ...config.style.styleParams!.colors![colorKey],
            value: userSettings[colorKey]
              ? mergeOpacityToColor(
                  userSettings[colorKey].value,
                  config.style.styleParams!.colors![colorKey]
                    .value as any as string,
                )
              : config.style.styleParams!.colors![colorKey].value,
          };
        });

        userSettings = {
          ...userSettings,
          ...(isAnywhereFlowInd ? {} : config.style.styleParams.fonts),
          ...userStylesColorsWithOpacity,
        };
        currentUserAppSettings = userSettings;
        currentUserStylesParam = config.style.styleParams;

        stylesProp = dangerousStylesOverride(
          mergeAppSettingsToStyleParams(
            userSettings,
            {
              booleans: {},
              numbers: {},
              googleFontsCssUrl: '',
            },
            presetId,
          ) as any,
        );

        const publicDataOverrides = isAnywhereFlowInd
          ? mapPublicDataOverridesToPublicData(businessInfo?.name)
          : undefined;

        publicData = dangerousPublicDataOverride(
          mergeAppSettingsToSettingsParams(
            userSettings,
            config.publicData,
            presetId,
            publicDataOverrides,
          ),
        );
      } else {
        stylesProp = dangerousStylesOverride(
          mergePresetIdToStyleParams(config.style.styleParams, presetId),
        );

        publicData = dangerousPublicDataOverride(
          mergePresetIdToPublicData(config.publicData, presetId),
        );
      }

      if (
        shouldShowDummyContent({
          services: enrichedServices,
          flowAPI,
        })
      ) {
        enrichedServices = createDummyServicesDto(flowAPI, presetId);
        filterOptions = createDummyFilterOptions(
          presetId,
          flowAPI,
          businessLocations,
          enrichedServices,
        );
      }

      widgetViewModel = await createWidgetViewModel({
        scale,
        flowAPI,
        viewMode,
        servicesPagingMetadata,
        shouldWorkWithAppSettings,
        businessLocations,
        allServices: enrichedServices,
        serviceListContext,
        appSettings: userSettings,
        filterOptions,
      });

      const widgetActions = createWidgetActions({
        activeFeatures,
        isPricingPlanInstalled,
        widgetViewModel,
        bookingsApi,
        flowAPI,
        setProps,
        services: enrichedServices,
        serviceListContext,
      });

      if (!isSSR) {
        widgetActions.getAdditionalServicesData();
      }

      if (!isSSR && !isEditor) {
        const origin =
          getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.STAFF) ||
          getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.RESOURCE)
            ? REQUESTED_STAFF_DEEP_LINK_ORIGIN
            : undefined;

        const filterServicesBy =
          shouldWorkWithAppSettings && userSettings?.CATEGORIES_TYPE
            || flowAPI.settings.get(settingsParams.filterServicesBy);

        bi?.report(
          bookingsWidgetPageLoaded({
            numOfTabs: widgetViewModel.headerViewModel.isListFilterVisible
              ? widgetViewModel.filterOptions.length
              : 0,
            numOfServices: widgetViewModel.services.length,
            isExplorePlans:
              widgetViewModel.bodyViewModel
                .atLeastOneServiceHasExplorePlansLink,
            origin,
            layout: widgetViewModel.serviceListLayout,
            areTabsLocationsOrServiceCategories: filterServicesBy,
          }),
        );
      }

      if (shouldWorkWithAppSettings && isEditor && isRunningInIframe()) {
        appSettingsClient.onChange(
          async (newUserSettings: ServiceListSettings) => {
            currentUserAppSettings = newUserSettings;
            updatePublicData({
              newUserSettings,
              presetId,
              flowAPI,
              dangerousStylesOverride,
              dangerousPublicDataOverride,
              scale,
              viewMode,
              newUserStylesSettings: currentUserStylesParam,
              shouldWorkWithAppSettings,
              businessLocations,
              serviceListContext,
              services: enrichedServices,
              bookingsApi,
              servicesPagingMetadata,
            });
          },
        );
      }
      if (
        getRequestedPageFromQueryParam(wixCodeApi) >
        servicesPagingMetadata.totalPages
      ) {
        wixCodeApi.seo.setSeoStatusCode(404);
      }

      setProps({
        ...stylesProp,
        ...publicData,
        widgetViewModel: { ...widgetViewModel },
        widgetActions,
        fitToContentHeight: true,
      });

      wixCodeApi.seo.renderSEOTags({
        itemType: ITEM_TYPES.SERVICES_COMPONENT,
        itemData: {
          services: enrichedServices,
          pagination: getPaginationSEOMetadata(
            wixCodeApi,
            servicesPagingMetadata,
          ),
        },
      });
    };
    return {
      pageReady,
      updateConfig(_$w, data) {
        shouldWorkWithAppSettings = !!data.externalId;
        if (shouldWorkWithAppSettings) {
          // Should have been relevant for ADI Editor OOI only when changing the design,
          // for other editors it's not relevant since all data is on app settings, and we have updateAppSettings function.
          // When we tried to wrap it isADI & !isRunningInIframe the component was rendered with defaults settings when we opened the settings panel.
          // WA - save the current most updated app settings data and call generic function to calculate public data/ styles param and widget view model
          presetId = isADI ? data.publicData.COMPONENT.presetId : presetId;
          currentUserStylesParam = data.style.styleParams;
          updatePublicData({
            businessLocations,
            dangerousPublicDataOverride,
            dangerousStylesOverride,
            flowAPI,
            newUserSettings: currentUserAppSettings,
            presetId,
            scale,
            serviceListContext,
            services: enrichedServices,
            shouldWorkWithAppSettings,
            viewMode,
            newUserStylesSettings: currentUserStylesParam,
            bookingsApi,
            servicesPagingMetadata,
          });
        } else {
          pageReady();
        }
      },
      updateAppSettings: (_event: any, updates: { [key: string]: any }) => {
        if (shouldWorkWithAppSettings) {
          // Relevant for editor OOI, changes on app settings, include texts/colors/fonts changes - covers all
          const { payload }: { payload: ServiceListSettings } = updates as any;
          if (!isRunningInIframe()) {
            currentUserAppSettings = payload;
            updatePublicData({
              businessLocations,
              dangerousPublicDataOverride,
              dangerousStylesOverride,
              flowAPI,
              newUserSettings: currentUserAppSettings,
              presetId,
              scale,
              serviceListContext,
              services: enrichedServices,
              shouldWorkWithAppSettings,
              viewMode,
              newUserStylesSettings: currentUserStylesParam,
              bookingsApi,
              servicesPagingMetadata,
            });
          }
        }
      },
    };
  };

  return createController;
};

export default createControllerFactory(ViewMode.PAGE);

const mapDeprecatedActiveFeaturesToGetActiveFeaturesResponse = ({
  course,
  groups,
  individual,
  payments,
  reminders,
  smsReminders,
  staffLimit,
  staffMembers,
}: ActiveFeatures): GetActiveFeaturesResponse => ({
  applicableForPayments: payments,
  applicableForCourse: course,
  applicableForGroups: groups,
  applicableForIndividual: individual,
  applicableForReminders: reminders,
  applicableForSmsReminders: smsReminders,
  bookingsStaffLimit: staffLimit,
  bookingsStaffMembersLimit: {
    isApplied: staffMembers,
    limit: staffLimit,
  },
});
