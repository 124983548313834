import { IStylesParamsColorsKeys } from '../../src/components/BookOnline/stylesParams';
import { MobileSettingsKeys, SettingsKeys } from '../types';
import { cleanNulls } from '../utils';
import { ServiceListSettings } from './appSettings';
import { IWixStyleColor, ISettingsColor } from '@wix/tpa-settings';

export interface AppSettingsColor {
  value: string;
  opacity: number;
  themeName: string;
}

const stylesParamColorKeyToAppSettingsMapper: {
  [key in IStylesParamsColorsKeys]: SettingsKeys | MobileSettingsKeys;
} = {
  bookButtonBackgroundColor: SettingsKeys.BUTTON_BACKGROUND_COLOR,
  bookButtonHoverBackgroundColor: SettingsKeys.BUTTON_HOVER_BACKGROUND_COLOR,
  bookButtonBorderColor: SettingsKeys.BUTTON_BORDER_COLOR,
  bookButtonHoverBorderColor: SettingsKeys.BUTTON_HOVER_BORDER_COLOR,
  fillBookButtonTextColor: SettingsKeys.BUTTON_TEXT_COLOR_FILL,
  holeBookButtonTextColor: SettingsKeys.BUTTON_TEXT_COLOR_HOLE,
  bookButtonHoverTextColor: SettingsKeys.BUTTON_HOVER_TEXT_COLOR,
  explorePlansTextColor: SettingsKeys.EXPLORE_PLANS_TEXT_COLOR,
  borderColor: SettingsKeys.BORDER_COLOR,
  dropdownBackgroundColor: MobileSettingsKeys.MOBILE_CATEGORY_BACKGROUND_COLOR,
  dropdownBorderColor: MobileSettingsKeys.MOBILE_CATEGORY_BORDER_COLOR,
  dropdownTextColor: MobileSettingsKeys.MOBILE_CATEGORY_NAME_COLOR,
  filterBorderColor: SettingsKeys.CATEGORY_DIVIDER_COLOR,
  filterHoverBorderColor: SettingsKeys.CATEGORY_HOVER_BORDER_COLOR,
  filterHoverIndicationColor: SettingsKeys.CATEGORY_HOVER_BACKGROUND_COLOR,
  filterHoverTextColor: SettingsKeys.CATEGORY_HOVER_TEXT_COLOR,
  filterSelectedBorderColor: SettingsKeys.CATEGORY_SELECTED_BORDER_COLOR,
  filterSelectedIndicationColor: SettingsKeys.CATEGORY_SELECTED_UNDERLINE_COLOR,
  filterSelectedTextColor: SettingsKeys.CATEGORY_SELECTED_TEXT_COLOR,
  filterTitleColor: SettingsKeys.CATEGORY_NAME_COLOR,
  infoBackgroundColor: SettingsKeys.BACKGROUND_COLOR,
  moreInfoButtonColor: SettingsKeys.OFFERING_MORE_INFO_LABEL_COLOR,
  serviceDetailsColor: SettingsKeys.OFFERING_DETAILS_COLOR,
  courseAvailabilityColor: SettingsKeys.COURSE_AVAILABILITY_COLOR,
  serviceDividerColor: SettingsKeys.DIVIDER_COLOR,
  serviceImageBackgroundColor: SettingsKeys.IMAGE_BACKGROUND_COLOR,
  serviceNameColor: SettingsKeys.OFFERING_NAME_COLOR,
  serviceTaglineColor: SettingsKeys.OFFERING_TAGLINE_COLOR,
  servicesDividerColor: SettingsKeys.SERVICE_DIVIDER_COLOR,
  widgetBackgroundColor: SettingsKeys.MULTI_OFFERINGS_BACKGROUND_COLOR,
  widgetTitleColor: SettingsKeys.MULTI_OFFERINGS_TITLE_COLOR,
  filterBackgroundColor: SettingsKeys.CATEGORY_BACKGROUND_COLOR,
  fillLoadServicesButtonTextColor: SettingsKeys.LOAD_SERVICES_BUTTON_TEXT_COLOR_FILL,
  loadServicesButtonBackgroundColor:
    SettingsKeys.LOAD_SERVICES_BUTTON_BACKGROUND_COLOR,
  loadServicesButtonBorderColor: SettingsKeys.LOAD_SERVICES_BUTTON_BORDER_COLOR,
};

export function mergeAppSettingsColorsToStyleParamsColors(
  appSettings: ServiceListSettings,
  colors?: { [key: string]: IWixStyleColor },
) {
  function mapColor({
    appSettingsColor,
    styleParamsColorKey,
  }: {
    appSettingsColor?: AppSettingsColor;
    styleParamsColorKey: IStylesParamsColorsKeys;
  }): ISettingsColor | IWixStyleColor | null {
    if (colors?.[styleParamsColorKey]) {
      return colors[styleParamsColorKey];
    }
    if (appSettingsColor) {
      return {
        value: appSettingsColor.value,
        opacity: appSettingsColor.opacity,
      };
    }
    return null;
  }
  const newColors: {
    [key in IStylesParamsColorsKeys]?: IWixStyleColor | null;
  } = {};
  (
    Object.keys(
      stylesParamColorKeyToAppSettingsMapper,
    ) as IStylesParamsColorsKeys[]
  ).forEach((styleParamsColorKey) => {
    newColors[styleParamsColorKey] = mapColor({
      appSettingsColor: appSettings[
        stylesParamColorKeyToAppSettingsMapper[styleParamsColorKey]
      ] as AppSettingsColor,
      styleParamsColorKey,
    });
  });

  return cleanNulls(newColors);
}
