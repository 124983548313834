import {
  BookingsQueryParams,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { IWixAPI } from '@wix/yoshi-flow-editor';
import { ServicesPagingMetadata } from '../../types/types';

export const getRequestedPageFromQueryParam = (wixCodeApi: IWixAPI) => {
  const page = getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.PAGE);
  const pageNumber = Number(page);

  if (!page || isNaN(pageNumber)) {
    return 1;
  }

  return pageNumber;
};

export const getPaginationSEOMetadata = (
  wixCodeApi: IWixAPI,
  servicesPagingMetadata: ServicesPagingMetadata,
) => {
  const { totalPages } = servicesPagingMetadata;
  const currentPage = getRequestedPageFromQueryParam(wixCodeApi);

  const hasPrevPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  const prevUrl = hasPrevPage
    ? generatePageUrl(wixCodeApi, currentPage - 1)
    : '';
  const nextUrl = hasNextPage
    ? generatePageUrl(wixCodeApi, currentPage + 1)
    : '';

  return {
    prevUrl,
    nextUrl,
    totalPages,
    currentPage,
  };
};

const generatePageUrl = (wixCodeApi: IWixAPI, pageNumber: number) => {
  const { location } = wixCodeApi;
  const url = [location.baseUrl, ...location.path].join('/');

  return pageNumber === 1
    ? url
    : `${url}?${BookingsQueryParams.PAGE}=${pageNumber}`;
};

export const calculateNumberOfPages = (
  servicesPerPage: number,
  totalItems?: number | null,
) => (totalItems ? Math.ceil(totalItems / servicesPerPage) : 1);
